import { Banner } from "../../util/Banner"
import { useTranslation } from "react-i18next"
import { useDropzone } from "react-dropzone"
import { useCallback, useEffect, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useHistory, useParams } from "react-router-dom"
import { Loader } from "../../util/Loader"
import { uniqueId } from "lodash"
import inputStyle from "../../../style/input.module.css"
import style from "./GroupUpload.module.css"
import cn from "classnames"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import tableStyle from "../../../style/table.module.css"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { showError } from "../../../redux/actions/NotificationActions"
import { useDispatch } from "react-redux"
import downloadCsv from "js-file-download"
import { Toggle } from "../../util/input/Toggle"

const UPLOAD_MARKETING_FILE = gql`
    mutation ($file: Upload!, $name: String!, $force: Boolean, $id: UUID) {
        createMarketingListFromCsv(
            file: $file
            name: $name
            force: $force
            id: $id
        ) {
            id
        }
    }
`

const UPLOAD_ALLOCATION_FILE = gql`
    mutation ($file: Upload!, $name: String!, $force: Boolean, $id: UUID) {
        createAllocationListFromCsv(
            file: $file
            name: $name
            force: $force
            id: $id
        ) {
            id
        }
    }
`

const GET_MARKETING_LIST = gql`
    query ($id: UUID!) {
        list: marketingList(id: $id) {
            id
            name
            pricingLists {
                id
                name
            }
        }
    }
`

const GET_ALLOCATION_LIST = gql`
    query ($id: UUID!) {
        list: allocationList(id: $id) {
            id
            name
            pricingLists {
                id
                name
            }
        }
    }
`

const GET_PRICING_LISTS = gql`
    query {
        pricingLists(
            limit: -1
            filterParams: { systemStatusEquals: "ACTIVE" }
        ) {
            page {
                id
                name: displayName
            }
        }
    }
`

export const MarketingUpload = ({ isAllocation }) => {
    const { t } = useTranslation(["enums", "table", "common"])
    const history = useHistory()
    const { id } = useParams()

    const [name, setName] = useState("")
    const [file, setFile] = useState("")
    const [force, setForce] = useState(false)
    const [pricingLists, setPricingLists] = useState([])

    const [name_input_id] = useState(() => uniqueId("name_input_"))
    const [type_pricing_lists_id] = useState(() =>
        uniqueId("type_pricing_lists_"),
    )
    const [ignore_missing_product_id] = useState(() =>
        uniqueId("ignore_missing_product_"),
    )

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles[0]) {
            setFile(acceptedFiles[0])
            if (!name) {
                setName(acceptedFiles[0].name || "")
            }
        }
    }, [])

    const [upload, { loading }] = useMutation(
        isAllocation ? UPLOAD_ALLOCATION_FILE : UPLOAD_MARKETING_FILE,
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    const { data, loading: marketingListLoading } = useQuery(
        isAllocation ? GET_ALLOCATION_LIST : GET_MARKETING_LIST,
        {
            variables: {
                id,
            },
            skip: !id,
        },
    )

    const { data: pricingListData, loading: pricingListLoading } = useQuery(
        GET_PRICING_LISTS,
        { skip: isAllocation },
    )

    useEffect(() => {
        setName(data?.list?.name || "")
        setPricingLists(data?.list?.pricingLists?.map((p) => p.id) || [])
    }, [data])

    const dispatch = useDispatch()

    if (loading || pricingListLoading) {
        return (
            <>
                <Banner
                    text={t(
                        isAllocation
                            ? "table:upload_allocation"
                            : "table:upload_marketing",
                    )}
                />
                <Loader />
            </>
        )
    }

    return (
        <>
            <Banner
                text={t(
                    isAllocation
                        ? "table:upload_allocation"
                        : "table:upload_marketing",
                )}
            />

            <div className={tableStyle.stackedFilterBar}>
                {loading || (id && marketingListLoading) ? (
                    <Loader />
                ) : (
                    <div className={tableStyle.filterBar}>
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={name_input_id}
                                className={tableStyle.label}
                            >
                                {t("table:name")}
                            </label>
                            <input
                                id={name_input_id}
                                className={inputStyle.text}
                                type="text"
                                value={name}
                                onChange={(evt) => setName(evt.target.value)}
                            />
                        </div>
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={ignore_missing_product_id}
                                className={tableStyle.label}
                            >
                                {t("table:ignore_missing_product")}
                            </label>
                            <Toggle
                                id={ignore_missing_product_id}
                                isChecked={force}
                                setIsChecked={setForce}
                            />
                        </div>
                        {isAllocation ? null : (
                            <div className={tableStyle.filterInnerSection}>
                                <label
                                    htmlFor={type_pricing_lists_id}
                                    className={tableStyle.label}
                                >
                                    {t("table:pricing_lists")}
                                </label>
                                <Select
                                    isMulti={true}
                                    className={style.selectMany}
                                    placeholder={""}
                                    value={pricingLists?.map((a) => ({
                                        value: a,
                                        label: pricingListData?.pricingLists?.page.find(
                                            (as) => as.id === a,
                                        )?.name,
                                    }))}
                                    onChange={(as) =>
                                        setPricingLists(as.map((a) => a.value))
                                    }
                                    loadingMessage={() => t("common:loading")}
                                    isLoading={pricingListLoading}
                                    components={makeAnimated()}
                                    options={pricingListData?.pricingLists?.page?.map(
                                        (a) => ({
                                            value: a.id,
                                            label: a.name,
                                        }),
                                    )}
                                    id={type_pricing_lists_id}
                                />
                            </div>
                        )}
                    </div>
                )}
                <div className={tableStyle.buttonSection}>
                    <button
                        className={buttonStyle.button}
                        disabled={!name || !file}
                        onClick={() => {
                            upload({
                                variables: {
                                    file,
                                    name,
                                    force,
                                    id: id,
                                },
                            })
                                .then((r) => {
                                    history.push(
                                        isAllocation
                                            ? `/allocation/${r?.data?.createAllocationListFromCsv?.id}`
                                            : `/marketing/${r?.data?.createMarketingListFromCsv?.id}`,
                                    )
                                })
                                .catch((e) => dispatch(showError(e.message)))
                        }}
                    >
                        {c(t("table:upload"))}
                    </button>
                    <button
                        onClick={() =>
                            downloadCsv(
                                isAllocation
                                    ? "pzn;valid_from;valid_until;extra_condition;bundle"
                                    : "pzn;marketing_type;valid_from;valid_until;zeitkritisch",
                                isAllocation
                                    ? "allocation_upload.csv"
                                    : "marketing_upload.csv",
                                "application/csv",
                            )
                        }
                        className={buttonStyle.button}
                    >
                        {t("table:download_template_csv")}
                    </button>
                </div>
            </div>

            {!file && (
                <div className={style.dropWrapper}>
                    <div
                        className={cn(
                            style.dropZone,
                            isDragActive && style.highlight,
                        )}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <h2>{t("table:drop_to_upload")}</h2>
                        ) : (
                            <h2>{t("table:drag_to_upload")}</h2>
                        )}
                    </div>
                </div>
            )}
            {file && (
                <div className={style.uploadedList}>
                    <div className={style.fileBox}>
                        <p>{file?.name || t("table:file")}</p>
                        <button
                            className={cn(
                                tableStyle.actionButton,
                                tableStyle.negativeActionButton,
                            )}
                            onClick={() => setFile(null)}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
