import styles from "./SideNav.module.css"
import { useTranslation } from "react-i18next"
import {
    faArrowLeft,
    faCalculator,
    faHome,
    faLanguage,
    faLock,
    faMicrochip,
    faRightFromBracket,
    faSliders,
    faTag,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { Link } from "react-router-dom"
import cn from "classnames"
import { gql, useQuery } from "@apollo/client"
import logo from "../../res/logo.png"
import { cycleLanguage } from "../../i18n"
import ContentLoader from "react-content-loader"
import { Placeholder } from "./placeholder/Placeholder"

/**
 * Query to get the username, picture and the state if a global lock is set
 *
 * @type {DocumentNode}
 */
const ME_QUERY = gql`
    query {
        me {
            name
            image
            id
        }
        globalLock {
            type
            reason
        }
    }
`

/**
 * Placeholder for before the profile has loaded (horizontal mode)
 *
 * @returns {JSX.Element} the placeholder element
 * @constructor
 */
const ProfilePlaceholder = () => (
    <ContentLoader
        viewBox="0 0 400 100"
        color={"black"}
        className={styles.userBox}
    >
        <circle cx="335" cy="50" r="36" />
        <rect x="65" y="53" rx="4" ry="4" width="222" height="10" />
    </ContentLoader>
)

/**
 * Plaveholder for before the profile has loaded (vertical mode)
 *
 * @returns {JSX.Element} the placeholder element
 * @constructor
 */
const VerticalProfilePlaceholder = () => (
    <ContentLoader
        viewBox="0 0 50 50"
        color={"black"}
        className={styles.verticalUserBoxPlaceholder}
    >
        <circle cx="25" cy="25" r="16" />
    </ContentLoader>
)

/**
 * Wrapper element that displays the inner content with a sidebar based navigation.
 * The sidebar contains the navigation links, a language switcher  and a logout button
 *
 * @param children The page content
 * @returns {JSX.Element} the sidenav element
 * @constructor
 */
export const SideNav = ({ children }) => {
    const { t } = useTranslation(["common", "page_names"])

    const { data, loading } = useQuery(ME_QUERY)

    const [isExpanded, setExpanded] = useState(false)

    const [expandedSection, setExpandedSection] = useState(0)

    return (
        <div className={styles.sideNavWrapper}>
            <div className={styles.sideNav} onClick={() => setExpanded(true)}>
                <div>
                    <div>
                        {isExpanded ? (
                            <a
                                href="/"
                                className={cn(styles.logo, styles.logoExpanded)}
                            >
                                <img alt="Logo" src={logo} />
                            </a>
                        ) : (
                            <span className={styles.logo}>
                                <img alt="Logo" src={logo} />
                            </span>
                        )}
                    </div>
                    <div className={styles.navButtons}>
                        <div
                            style={{ display: isExpanded ? "initial" : "none" }}
                        >
                            <div
                                className={styles.navBackHeader}
                                onClick={() => {
                                    setTimeout(() => setExpanded(false), 1)
                                    setExpandedSection(0)
                                }}
                            >
                                <button className={styles.iconButton}>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </button>
                            </div>
                        </div>

                        <div>
                            <div
                                className={cn(styles.navHeader)}
                                style={{
                                    justifyContent: isExpanded
                                        ? "flex-start"
                                        : "center",
                                }}
                            >
                                <button className={cn(styles.iconButton)}>
                                    <FontAwesomeIcon icon={faHome} />
                                </button>
                                <Link to={"/"}>
                                    <h1
                                        style={{
                                            display: isExpanded
                                                ? "block"
                                                : "none",
                                        }}
                                    >
                                        {t("page_names:home")}
                                    </h1>
                                </Link>
                            </div>
                        </div>

                        <div>
                            <div
                                className={cn(
                                    styles.navHeader,
                                    expandedSection === 1 ? styles.green : null,
                                )}
                                onClick={() =>
                                    setExpandedSection(
                                        expandedSection === 1 ? 0 : 1,
                                    )
                                }
                                style={{
                                    justifyContent: isExpanded
                                        ? "flex-start"
                                        : "center",
                                }}
                            >
                                <button
                                    className={cn(
                                        styles.iconButton,
                                        expandedSection === 1
                                            ? styles.green
                                            : null,
                                    )}
                                >
                                    <FontAwesomeIcon icon={faCalculator} />
                                </button>
                                <h1
                                    style={{
                                        display: isExpanded ? "block" : "none",
                                    }}
                                >
                                    {t("page_names:section_calculation")}
                                </h1>
                            </div>
                            <ul
                                className={styles.navList}
                                style={{
                                    display:
                                        expandedSection === 1
                                            ? "block"
                                            : "none",
                                }}
                            >
                                <li>
                                    <Link to={"/new_pricing_cycle"}>
                                        {t("page_names:new_pricing_cycle")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/calculation_settings"}>
                                        {t("page_names:set_due_dates")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/assortments"}>
                                        {t("page_names:assortments")}
                                    </Link>
                                </li>


                                <li>
                                    <Link to={"/missing_min_price_products"}>
                                        {t("page_names:missing_min_price_products")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/pricing_lists"}>
                                        {t("page_names:pricing_lists")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/enlist_and_delist/csv"}>
                                        {t("page_names:manual_listing")} (CSV)
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/price/bulk/csv"}>
                                        {t("page_names:manual_bulk_prices")}{" "}
                                        (CSV)
                                    </Link>
                                </li>
                                {/*
                                <li>
                                    <Link to={"/price/csv"}>
                                        {t("page_names:manual_prices")} (CSV)
                                    </Link>
                                </li>
                                */}
                                <li>
                                    <Link to={"/plausibility_checks"}>
                                        {t("page_names:plausibility_checks")}
                                    </Link>
                                </li>
                                {/*
                                <li>
                                    <Link to={"/new_plausibility_check"}>
                                        {t("page_names:new_plausibility_check")}
                                    </Link>
                                </li>
                                */}
                                <li>
                                    <Link to={"/ifa_changes"}>
                                        {t("page_names:ifa_changes")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/export"}>
                                        {t("page_names:export")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/new_correction_list"}>
                                        {t("page_names:new_correction_list")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/products"}>
                                        {t("page_names:products")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div
                                className={cn(
                                    styles.navHeader,
                                    expandedSection === 2 ? styles.green : null,
                                )}
                                onClick={() =>
                                    setExpandedSection(
                                        expandedSection === 2 ? 0 : 2,
                                    )
                                }
                                style={{
                                    justifyContent: isExpanded
                                        ? "flex-start"
                                        : "center",
                                }}
                            >
                                <button
                                    className={cn(
                                        styles.iconButton,
                                        expandedSection === 2
                                            ? styles.green
                                            : null,
                                    )}
                                >
                                    <FontAwesomeIcon icon={faTag} />
                                </button>
                                <h1
                                    style={{
                                        display: isExpanded ? "block" : "none",
                                    }}
                                >
                                    {t("page_names:section_marketing")}
                                </h1>
                            </div>
                            <ul
                                className={styles.navList}
                                style={{
                                    display:
                                        expandedSection === 2
                                            ? "block"
                                            : "none",
                                }}
                            >
                                <li>
                                    <Link to={"/marketing"}>
                                        {t("page_names:marketing")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/marketing/check_marketing_listings"}>
                                        {t("page_names:check_marketing_listings")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/marketing/check_marketing_equality"}>
                                        {t("page_names:check_marketing_equality")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/allocation"}>
                                        {t("page_names:allocation")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div
                                className={cn(
                                    styles.navHeader,
                                    expandedSection === 3 ? styles.green : null,
                                )}
                                onClick={() =>
                                    setExpandedSection(
                                        expandedSection === 3 ? 0 : 3,
                                    )
                                }
                                style={{
                                    justifyContent: isExpanded
                                        ? "flex-start"
                                        : "center",
                                }}
                            >
                                <button
                                    className={cn(
                                        styles.iconButton,
                                        expandedSection === 3
                                            ? styles.green
                                            : null,
                                    )}
                                >
                                    <FontAwesomeIcon icon={faSliders} />
                                </button>
                                <h1
                                    style={{
                                        display: isExpanded ? "block" : "none",
                                    }}
                                >
                                    {t("page_names:section_configuration")}
                                </h1>
                            </div>
                            <ul
                                className={styles.navList}
                                style={{
                                    display:
                                        expandedSection === 3
                                            ? "block"
                                            : "none",
                                }}
                            >
                                <li>
                                    <Link to={"/distributors"}>
                                        {t("page_names:distributors")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/strategies"}>
                                        {t("page_names:strategies")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/pricing_list"}>
                                        {t(
                                            "page_names:create_new_pricing_list",
                                        )}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/targeted_data_updates"}>
                                        {t(
                                            "page_names:targeted_data_updates_overview",
                                        )}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/targeted_data_updates/csv"}>
                                        {t("page_names:targeted_data_updates")}{" "}
                                        (CSV)
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/add_pzn"}>
                                        {t("page_names:add_pzn")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/rule_strategy"}>
                                        {t("page_names:add_new_rule_strategy")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/ml_strategy"}>
                                        {t("page_names:add_new_ml_strategy")}
                                    </Link>
                                </li>

                                <li>
                                    <Link
                                        to={"/model_configuration_mass_assign"}
                                    >
                                        {t(
                                            "page_names:article_group_strategy_allocation",
                                        )}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/article_group/csv"}>
                                        {t(
                                            "page_names:article_group_for_alt_strategy",
                                        )}{" "}
                                        (CSV)
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div
                                className={cn(
                                    styles.navHeader,
                                    expandedSection === 4 ? styles.green : null,
                                )}
                                onClick={() =>
                                    setExpandedSection(
                                        expandedSection === 4 ? 0 : 4,
                                    )
                                }
                                style={{
                                    justifyContent: isExpanded
                                        ? "flex-start"
                                        : "center",
                                }}
                            >
                                <button
                                    className={cn(
                                        styles.iconButton,
                                        expandedSection === 4
                                            ? styles.green
                                            : null,
                                    )}
                                >
                                    <FontAwesomeIcon icon={faMicrochip} />
                                </button>
                                <h1
                                    style={{
                                        display: isExpanded ? "block" : "none",
                                    }}
                                >
                                    {t("page_names:section_processes")}
                                </h1>
                            </div>
                            <ul
                                className={styles.navList}
                                style={{
                                    display:
                                        expandedSection === 4
                                            ? "block"
                                            : "none",
                                }}
                            >
                                <li>
                                    <Link to={"/tasks"}>
                                        {t("page_names:tasks")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/calculations"}>
                                        {t("page_names:calculations")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/run_info"}>
                                        {t("page_names:run_info")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/assortment_groups"}>
                                        {t("page_names:assortment_groups")}
                                    </Link>
                                </li>

                                <li>
                                    <Link to={"/pricing_list_groups"}>
                                        {t("page_names:pricing_list_groups")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {isExpanded ? (
                    <Placeholder
                        ready={!loading && data?.me}
                        placeholder={<ProfilePlaceholder />}
                    >
                        <div className={styles.userBox}>
                            <div>
                                <p>{data?.me?.name}</p>
                                <div className={styles.buttonBox}>
                                    {data?.globalLock ? (
                                        <FontAwesomeIcon
                                            className={cn(
                                                styles.navButton,
                                                styles.locked,
                                            )}
                                            icon={faLock}
                                            title={data?.globalLock?.reason}
                                        />
                                    ) : null}
                                    <FontAwesomeIcon
                                        className={styles.navButton}
                                        icon={faLanguage}
                                        onClick={() => cycleLanguage()}
                                    />
                                    <a href="/auth/logout">
                                        <FontAwesomeIcon
                                            className={styles.navButton}
                                            icon={faRightFromBracket}
                                        />
                                    </a>
                                </div>
                            </div>
                            <img alt={data?.me?.name} src={data?.me?.image} />
                        </div>
                    </Placeholder>
                ) : (
                    <div className={styles.verticalUserBox}>
                        {data?.globalLock ? (
                            <FontAwesomeIcon
                                className={cn(styles.navButton, styles.locked)}
                                icon={faLock}
                                title={data?.globalLock?.reason}
                            />
                        ) : null}
                        <FontAwesomeIcon
                            className={styles.navButton}
                            icon={faLanguage}
                            onClick={() => cycleLanguage()}
                        />
                        <a href="/auth/logout">
                            <FontAwesomeIcon
                                className={styles.navButton}
                                icon={faRightFromBracket}
                            />
                        </a>
                        <Placeholder
                            ready={!loading && data?.me}
                            placeholder={<VerticalProfilePlaceholder />}
                        >
                            <img alt={data?.me?.name} src={data?.me?.image} />
                        </Placeholder>
                    </div>
                )}
            </div>
            <div
                className={cn(
                    styles.mainWrapper,
                    data?.globalLock ? styles.mainWrapperLocked : null,
                )}
            >
                {children}
            </div>
        </div>
    )
}
