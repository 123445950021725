import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import {gql, useMutation, useQuery} from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import cn from "classnames"
import {
    getCurrencyFormatter, getDayMonthYearFormatter,
    getIntegerFormatter,
    getSafeFormatter,
} from "../../../i18n"
import { ProductDetailExpander } from "../../util/ProductDetailExpander"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import {showError, showInfo} from "../../../redux/actions/NotificationActions"
import {useDispatch} from "react-redux"
import {Pagination} from "../../util/table/Pagination"
import {Link} from "react-router-dom"

const GET_PRODUCTS = gql`
    query($pageNum: Int!){
        latestValidMarketingList{
            id
            name
            chosenCalculation {
                id
                name
            }
        }
        marketingComparisonList(
            pageNum: $pageNum
            limit: 20
        ){
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                price1
                price2
                price3
                amount1
                amount2
                amount3
                calc201Price1
                calc201Price2
                calc201Price3
                calc201Amount1
                calc201Amount2
                calc201Amount3
                calc202Price1
                calc202Price2
                calc202Price3
                calc202Amount1
                calc202Amount2
                calc202Amount3
                calc203Price1
                calc203Price2
                calc203Price3
                calc203Amount1
                calc203Amount2
                calc203Amount3
                calc204Price1
                calc204Price2
                calc204Price3
                calc204Amount1
                calc204Amount2
                calc204Amount3
                calc205Price1
                calc205Price2
                calc205Price3
                calc205Amount1
                calc205Amount2
                calc205Amount3
                calc206Price1
                calc206Price2
                calc206Price3
                calc206Amount1
                calc206Amount2
                calc206Amount3
                product {
                    id
                    pzn
                    name
                }
            }
        }
    }
`

const DOWNLOAD_PRODUCTS = gql`
    mutation download{
        downloadCheckMarketingEquality
    }
`

export const MarketingPriceComparisonList = () => {
    const [expanderProduct, setExpanderProduct] = useState(null)

    const [loadProducts, setLoadProducts] = useState(false)
    const [pageNum, setPage] = useState(1)

    const dispatch = useDispatch()

    const { t, i18n } = useTranslation(["common", "table"])

    const dayMonthYearFormat = getSafeFormatter(
        getDayMonthYearFormatter(i18n.language),
    )

    const currencyFormat = getSafeFormatter(getCurrencyFormatter(i18n.language))
    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    const { data, loading } = useQuery(GET_PRODUCTS, {
        skip: !loadProducts,
        variables: {
            pageNum,
        }
    })

    const [download] = useMutation(DOWNLOAD_PRODUCTS)

    return (
        <div>
            <Banner text={t("table:check_marketing_equality")}/>
            {loadProducts && <div className={dropDownStyle.container}>
                <div className={tableStyle.buttonBar}>
                {data?.latestValidMarketingList?.id ?
                <Link to={`/marketing/${data?.latestValidMarketingList?.id}`}>
                    {t("table:from_upload") + ": " +  data?.latestValidMarketingList?.name + ` [${data?.latestValidMarketingList?.chosenCalculation?.name}]`}
                </Link>: <span/>
                }
                </div>
                <div className={tableStyle.buttonBar}>
                    <button
                        className={buttonStyle.button}
                        disabled={!data?.marketingComparisonList?.page?.length}
                        onClick={() => download().then(() =>
                                    dispatch(
                                        showInfo("common:export_triggered"),
                                    ),
                                )
                                .catch((e) => dispatch(showError(e.message)))}
                    >
                        {c(t("table:export_products"))}
                    </button>
                </div>
            </div>}
            {!loading && !data?.marketingComparisonList?.page?.length && loadProducts ? (
                <Flash text={t("table:no_results")} type={WARNING}/>
            ) : null}

            {! loadProducts && <div className={cn(tableStyle.buttonBar, tableStyle.centerButtonBar)}>
                <button
                    className={cn(buttonStyle.button, buttonStyle.bigButton)}
                    onClick={() => {
                        setLoadProducts(true)
                    }}
                >
                    {c(t("table:load_products"))}
                </button>
            </div>}

            {loadProducts &&
                <div className={tableStyle.wrapper}>
                <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "7em 14em repeat(42, 1fr)",
                }}
            >
                <TableHeader
                            className={tableStyle.header}
                            label={t("table:pzn")}
                            asc="ProductPznAsc"
                            desc="ProductPznDesc"
                        />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:name")}
                    asc="ProductNameAsc"
                    desc="ProductNameDesc"
                />


                <TableHeader
                    style={{gridColumn: "span 6"}}
                    label={t("table:marketing_list")}
                />

                {[...Array(6)].map((_, i) => (
                    <TableHeader
                        key={i}
                        style={{gridColumn: "span 6"}}
                        label={i + 201}
                    />
                ))}


                <TableHeader className={tableStyle.header}/>
                <TableHeader className={tableStyle.header}/>

                {[...Array(7)].map((_, i) => (
                <Fragment key={i}>

                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:amount") + " 1"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:price") + " 1"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:amount") + " 2"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:price") + " 2"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:amount") + " 3"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:price") + " 3"}
                    />
                </Fragment>
            ))}

                        {(loading) && !data?.list && (
                            <TableContentLoader columns={44} />
                        )}
                        {data?.marketingComparisonList?.page.map((s, i) => (
                            <Fragment key={i}>
                                <p
                                    className={tableStyle.cell}
                                    onClick={() =>
                                        setExpanderProduct({
                                            ...s?.product,
                                            row: i,
                                        })
                                    }
                                >
                                    {s?.product?.pzn}
                                </p>
                                <p
                                    className={cn(tableStyle.cell, tableStyle.dividerCell)}
                                    onClick={() =>
                                        setExpanderProduct({
                                            ...s?.product,
                                            row: i,
                                        })
                                    }
                                >
                                    {s?.product?.name}
                                </p>

                                <p
                                    className={cn(
                                        tableStyle.fullWidthCell,
                                    )}
                                >
                                    {integerFormatter.format(s?.amount1)}
                                </p>
                                <p
                                    className={cn(
                                        tableStyle.fullWidthCell,
                                    )}
                                >
                                    {currencyFormat.format(s?.price1)}
                                </p>
                                <p
                                    className={cn(
                                        tableStyle.fullWidthCell,
                                    )}
                                >
                                    {integerFormatter.format(s?.amount2)}
                                </p>
                                <p
                                    className={cn(
                                        tableStyle.fullWidthCell,
                                    )}
                                >
                                    {currencyFormat.format(s?.price2)}
                                </p>
                                <p
                                    className={cn(
                                        tableStyle.fullWidthCell,
                                    )}
                                >
                                    {integerFormatter.format(s?.amount3)}
                                </p>
                                <p
                                    className={cn(
                                        tableStyle.fullWidthCell,
                                        tableStyle.dividerCell,
                                    )}
                                >
                                    {currencyFormat.format(s?.price3)}
                                </p>

                                {[...Array(6)].map((_, i) => (
                                    <Fragment key={i}>

                                        <p
                                            className={cn(
                                                tableStyle.fullWidthCell,
                                            )}
                                        >
                                            {integerFormatter.format(s && s[`calc${201+i}Amount1`])}
                                        </p>
                                        <p
                                            className={cn(
                                                tableStyle.fullWidthCell,
                                            )}
                                        >
                                            {currencyFormat.format(s && s[`calc${201+i}Price1`])}
                                        </p>
                                        <p
                                            className={cn(
                                                tableStyle.fullWidthCell,
                                            )}
                                        >
                                            {integerFormatter.format(s && s[`calc${201+i}Amount2`])}
                                        </p>
                                        <p
                                            className={cn(
                                                tableStyle.fullWidthCell,
                                            )}
                                        >
                                            {currencyFormat.format(s && s[`calc${201+i}Price2`])}
                                        </p>
                                        <p
                                            className={cn(
                                                tableStyle.fullWidthCell,
                                            )}
                                        >
                                            {integerFormatter.format(s && s[`calc${201+i}Amount3`])}
                                        </p>
                                        <p
                                            className={cn(
                                                tableStyle.fullWidthCell,
                                                tableStyle.dividerCell,
                                            )}
                                        >
                                            {currencyFormat.format(s && s[`calc${201+i}Price3`])}
                                        </p>
                                    </Fragment>
                                ))}

                                <ProductDetailExpander
                                    isShown={
                                        expanderProduct && expanderProduct === s?.id
                                    }
                                    close={() => setExpanderProduct(null)}
                                    product={s}
                                />
                            </Fragment>
                        ))}
                </div>
                </div>
            }
            {loadProducts && <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.marketingComparisonList}
                loading={loading && !data?.marketingComparisonList}
            />}
        </div>
    )
}
