import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { Flash, WARNING } from "../../util/Flash"
import "../../../style/index.css"
import tableStyle from "../../../style/table.module.css"
import buttonStyle from "../../../style/button.module.css"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import cn from "classnames"
import { Banner } from "../../util/Banner"
import {
    getSafeFormatter,
    getTimeFormatter,
    getIntegerFormatter,
} from "../../../i18n"
import { DeletionWithAffectedModal } from "../../util/modals/DeletionWithAffectedModal"
import c from "capitalize"
import { SmartFilter } from "../../util/input/SmartFilter"

const MARKETING_LIST_QUERY = gql`
    query pricingListsList(
        $pageNum: Int
        $order: [MarketingListAllOrder]
        $filter: MarketingListAllFilter
    ) {
        lists: marketingLists(
            limit: 20
            pageNum: $pageNum
            orderBy: $order
            filterParams: $filter
        ) {
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                name
                createdAt
                updatedAt
                productAmount
            }
        }
    }
`

const MARKETING_FILTER_QUERY = gql`
    query getFilter {
        lists: marketingLists(limit: 0) {
            filterFields {
                name
                dataType
                displayName
                options
            }
        }
    }
`

const DELETE_MARKETING_LIST = gql`
    mutation ($id: UUID!, $ignoreAffected: Boolean) {
        delete: deleteMarketingList(id: $id, ignoreAffected: $ignoreAffected) {
            success
            affected {
                model
                name
                id
            }
            blockers {
                model
                name
                id
            }
        }
    }
`
const ALLOCATION_LIST_QUERY = gql`
    query pricingListsList(
        $pageNum: Int
        $order: [AllocationListAllOrder]
        $filter: AllocationListAllFilter
    ) {
        lists: allocationLists(
            limit: 20
            pageNum: $pageNum
            orderBy: $order
            filterParams: $filter
        ) {
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                name
                createdAt
                updatedAt
                productAmount
            }
        }
    }
`

const ALLOCATION_FILTER_QUERY = gql`
    query getFilter {
        lists: allocationLists(limit: 0) {
            filterFields {
                name
                dataType
                displayName
                options
            }
        }
    }
`

const DELETE_ALLOCATION_LIST = gql`
    mutation ($id: UUID!, $ignoreAffected: Boolean) {
        delete: deleteAllocationList(id: $id, ignoreAffected: $ignoreAffected) {
            success
            affected {
                model
                name
                id
            }
            blockers {
                model
                name
                id
            }
        }
    }
`
export const MarketingListList = ({ isAllocation }) => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [query, setQuery] = useState("")
    const [filter, setFilter] = useState(null)

    const { t, i18n } = useTranslation(["common", "table"])

    const { data, loading } = useQuery(
        isAllocation ? ALLOCATION_LIST_QUERY : MARKETING_LIST_QUERY,
        {
            variables: {
                pageNum,
                order,
                query,
                filter,
            },
        },
    )

    const [currentElement, setCurrentElement] = useState(null)

    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))
    const intFormat = getSafeFormatter(getIntegerFormatter(i18n.language))

    return (
        <div>
            <Banner
                text={t(
                    isAllocation
                        ? "table:allocation_lists"
                        : "table:marketing_lists",
                )}
            />

            {currentElement && (
                <DeletionWithAffectedModal
                    id={currentElement?.id}
                    delete_mutation={
                        isAllocation
                            ? DELETE_ALLOCATION_LIST
                            : DELETE_MARKETING_LIST
                    }
                    onDismiss={() => setCurrentElement(null)}
                    refetchQueries={[
                        MARKETING_LIST_QUERY,
                        ALLOCATION_LIST_QUERY,
                    ]}
                    onCancel={() => setCurrentElement(null)}
                    name={currentElement?.name}
                />
            )}

            <div className={dropDownStyle.container}>
                <SmartFilter
                    filterFieldQuery={
                        isAllocation
                            ? ALLOCATION_FILTER_QUERY
                            : MARKETING_FILTER_QUERY
                    }
                    filterPath={(q) => q?.lists?.filterFields}
                    setQuery={setQuery}
                    setFilter={setFilter}
                    suggestedQuery={data?.lists?.suggestedQuery}
                    hasSearch={false}
                />
                <div className={tableStyle.centerButtonBar}>
                <div className={tableStyle.buttonBar}>
                    <Link
                        to={isAllocation ? "/allocation/csv" : "/marketing/csv"}
                        className={buttonStyle.orangeButton}
                        style={isAllocation?null:{"width": "100%"}}
                    >
                        {c(
                            t(
                                isAllocation
                                    ? "table:add_new_allocation_list"
                                    : "table:add_new_marketing_list",
                            ),
                        )}
                    </Link>
                    <Link
                        to={isAllocation ? "/allocation/calculations" : "/marketing/calculations"}
                        className={buttonStyle.button}
                    >
                        {c(
                            t(
                                isAllocation
                                    ? "table:calculations"
                                    : "table:calculations",
                            ),
                        )}
                    </Link>
                </div>
                    </div>
            </div>

            {!loading && !data?.lists?.page?.length ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}
            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "repeat(4, 2fr) 0.5fr",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:marketing_list")}
                    asc="NameAsc"
                    desc="NameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:product_amount")}
                    asc="ProductAmountAsc"
                    desc="ProductAmountDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                    asc="CreatedAtAsc"
                    desc="CreatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                    asc="UpdatedAtAsc"
                    desc="UpdatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <p className={tableStyle.header} />
                {loading && !data?.pricingLists && (
                    <TableContentLoader columns={7} />
                )}
                {data?.lists?.page?.map((s, i) => (
                    <Fragment key={i}>
                        <Link
                            className={cn(tableStyle.linkCell, tableStyle.cell)}
                            to={
                                isAllocation
                                    ? `/allocation/${s.id}`
                                    : `/marketing/${s.id}`
                            }
                        >
                            {s.name}
                        </Link>
                        <p className={tableStyle.cell}>
                            {intFormat.format(s?.productAmount)}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s.createdAt))}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s.updatedAt))}
                        </p>
                        <p className={tableStyle.actionCell}>
                            <button
                                className={cn(
                                    tableStyle.actionButton,
                                    tableStyle.negativeActionButton,
                                )}
                                onClick={() => {
                                    setCurrentElement({
                                        id: s.id,
                                        name: s.name,
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.lists}
                loading={loading && !data?.lists}
            />
        </div>
    )
}
