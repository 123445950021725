import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import {gql, useMutation, useQuery} from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import cn from "classnames"
import {
    getCurrencyFormatter, getDayMonthYearFormatter,
    getIntegerFormatter,
    getSafeFormatter,
} from "../../../i18n"
import { ProductDetailExpander } from "../../util/ProductDetailExpander"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import {showError, showInfo} from "../../../redux/actions/NotificationActions";
import {useDispatch} from "react-redux";
import {Pagination} from "../../util/table/Pagination";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faX} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const GET_PRODUCTS = gql`
    query($pageNum: Int!){
        latestValidMarketingList{
            id
            name
            chosenCalculation {
                id
                name
            }
        }
        missingMarketingProductList(
            pageNum: $pageNum
            limit: 20
        ){
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                marketingType
                validFrom
                validUntil
                timeCritical
                product {
                    id
                    pzn
                    name
                    unit
                    supplierName
                    articleBlocked
                    dateOutOfDate
                }
            }
        }
    }
`

const DOWNLOAD_PRODUCTS = gql`
    mutation download{
        downloadCheckMarketingListings
    }
`

export const MissingMarketingProductList = () => {
    const [expanderProduct, setExpanderProduct] = useState(null)

    const [loadProducts, setLoadProducts] = useState(false)
    const [pageNum, setPage] = useState(1)

    const dispatch = useDispatch()

    const { t, i18n } = useTranslation(["common", "table"])

    const dayMonthYearFormat = getSafeFormatter(
        getDayMonthYearFormatter(i18n.language),
    )

    const { data, loading } = useQuery(GET_PRODUCTS, {
        skip: !loadProducts,
        variables: {
            pageNum,
        }
    })

    const [download] = useMutation(DOWNLOAD_PRODUCTS)

    return (
        <div>
            <Banner text={t("table:check_marketing_listings")}/>
            {loadProducts && <div className={dropDownStyle.container}>
                <div className={tableStyle.buttonBar}>
                {data?.latestValidMarketingList?.id ?
                <Link to={`/marketing/${data?.latestValidMarketingList?.id}`}>
                    {t("table:from_upload") + ": " +  data?.latestValidMarketingList?.name + ` [${data?.latestValidMarketingList?.chosenCalculation?.name}]`}
                </Link>: <span/>
                }
                </div>
                <div className={tableStyle.buttonBar}>
                    <button
                        className={buttonStyle.button}
                        disabled={!data?.missingMarketingProductList?.page?.length}
                        onClick={() => download().then(() =>
                                    dispatch(
                                        showInfo("common:export_triggered"),
                                    ),
                                )
                                .catch((e) => dispatch(showError(e.message)))}
                    >
                        {c(t("table:export_products"))}
                    </button>
                </div>
            </div>}
            {!loading && !data?.missingMarketingProductList?.page?.length && loadProducts ? (
                <Flash text={t("table:no_results")} type={WARNING}/>
            ) : null}

            {! loadProducts && <div className={cn(tableStyle.buttonBar, tableStyle.centerButtonBar)}>
                <button
                    className={cn(buttonStyle.button, buttonStyle.bigButton)}
                    onClick={() => {
                        setLoadProducts(true)
                    }}
                >
                    {c(t("table:load_products"))}
                </button>
            </div>}

            {loadProducts && <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "1fr 2fr repeat(4, 1fr) 2fr",
                }}
            >
                <TableHeader
                            className={tableStyle.header}
                            label={t("table:pzn")}
                            asc="ProductPznAsc"
                            desc="ProductPznDesc"
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:name")}
                            asc="ProductNameAsc"
                            desc="ProductNameDesc"
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:marketing_type")}
                            asc="MarketingTypeAsc"
                            desc="MarketingTypeDesc"
                        />

                    <TableHeader
                            className={tableStyle.header}
                            label={t("table:unit")}
                            asc="UnitAsc"
                            desc="UnitDesc"
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:article_blocked")}
                            asc="ArticleBlockedAsc"
                            desc="ArticleBlockedDesc"
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:date_out_of_date")}
                            asc="DateOutOfDateAsc"
                            desc="DateOutOfDateDesc"
                        />
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:supplier_name")}
                                asc="SupplierNameAsc"
                                desc="SupplierNameDesc"
                            />
                        {(loading) && !data?.list && (
                            <TableContentLoader columns={7} />
                        )}
                        {data?.missingMarketingProductList?.page.map((s, i) => (
                            <Fragment key={i}>
                                <p
                                    className={tableStyle.cell}
                                    onClick={() =>
                                        setExpanderProduct({
                                            ...s?.product,
                                            row: i,
                                        })
                                    }
                                >
                                    {s?.product?.pzn}
                                </p>
                                <p
                                    className={tableStyle.cell}
                                    onClick={() =>
                                        setExpanderProduct({
                                            ...s?.product,
                                            row: i,
                                        })
                                    }
                                >
                                    {s?.product?.name}
                                </p>
                                <p className={tableStyle.cell}>
                                    {s?.marketingType}
                                </p>
                                <p className={tableStyle.cell}>
                                    {s?.product?.unit}
                                </p>
                                <p className={cn(tableStyle.checkCell)}>
                                    {c.id ===
                                    s?.product?.articleBlocked ? (
                                        <FontAwesomeIcon icon={faCheck}/>
                                    ) : <FontAwesomeIcon icon={faX}/>}
                                </p>
                                <p
                                    className={cn(
                                        tableStyle.cell
                                    )}
                                >
                                    {dayMonthYearFormat.format(
                                        new Date(s?.product?.dateOutOfDate),
                                    )}
                                </p>
                                <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                                    {s?.product?.supplierName}
                                </p>

                                <ProductDetailExpander
                                    isShown={
                                        expanderProduct && expanderProduct === s?.id
                                    }
                                    close={() => setExpanderProduct(null)}
                                    product={s}
                                />
                            </Fragment>
                        ))}
            </div>}
            {loadProducts && <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.missingMarketingProductList}
                loading={loading && !data?.missingMarketingProductList}
            />}
        </div>
    )
}
